import {Component} from "react";
import {connect} from "react-redux";
import axios from "axios";
import {Alert, Autocomplete, Box, Button, TextField, Typography} from "@mui/material";
import imgUser from '../../assets/user.png';
import {Link} from "react-router-dom";
import {getUserThunk} from "../../redux/users/users.thunk";

class AssignUsers extends Component {

    state = {
        subscriptions: null,
        users: null,
        changed: [],
        success: false,
        selectedUsers: []
    }


    async componentDidMount() {

        let res = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/stripe/assigned/${this.props.selectedUser.id}`);
        console.log(res.data);
        this.setState({subscriptions: res.data});

        let res1 = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/slack/users/all/${this.props.selectedUser.teamId}`);
        console.log(res1.data);
        this.setState({users: res1.data});

        for (let sub of res.data) {
            let mychanged = [...this.state.changed];
            let obj = {id: sub.id, slackUserId: sub.slackUserId};
            mychanged[sub.id] = obj;
            this.setState({changed: mychanged});
        }


    }

    valueInitialUser = (userId) => {
        const data = this.state.users.find((user) => {
            return user.id === userId;
        });
        console.log('valueUser', data);
        return data;
    }

    handleChange = (e, option, item) => {
        const mychanged = [...this.state.changed];
        mychanged[item] = {id: item, slackUserId: option.id};
        this.setState({changed: mychanged});
        console.log('statee!', item, option);
    }

    saveChanges = async (e) => {
        console.log(e);
        console.log(this.state.changed.length);
        for (let i = 0; i < this.state.changed.length; i++) {

            let c = this.state.changed[i];
            console.log(c);
            console.log(this.state);
            if (this.state.changed[i] !== undefined) {
                let res = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/stripe/reassign`, {
                    id: c.id,
                    obj: {slackUserId: c.slackUserId}
                });
                console.log("Response");
                console.log(res);
                if (res.status === 201) {
                    this.setState({success: true});
                    setTimeout(()=> {
                        this.props.navigate('/');
                    }, 300);
                }
            } else continue;

        }
    }


    // displaySubscriptions(){
    //     if(this.state.subscriptions!=null){

    //         return this.state.subscriptions.map((sub, i)=> (<div>
    //             Susbcription: {sub.id}
    //             <br/>
    //             Assigned to: 


    //             {sub.slack_users.map((us, j)=>(
    //                 <div>
    //                     {us.email}
    //                 </div>
    //             ))}


    //         </div>))
    //     }
    // }

    displayAssignment() {

        if (this.state.subscriptions != null) {

            return this.state.subscriptions.length > 0 ? (this.state.subscriptions.map((sub, i) => (
                <div key={i}>

                <Typography variant="body1" gutterBottom>
                    Assign to :
                </Typography>

                {this.displayUsers(sub, sub.slackUserId)}

                <br/>

            </div>))) : (
                <p className="text-black-50 text-center"><small><em>There are no registered subscriptions</em></small></p>
            )
        }
    }


    displayUsers(assignment, user) {

        if (this.state.users !== null && this.state.changed[assignment.id] !== undefined) {
            // let mySelectedUsers;
            // for (let us of this.state.users){
            //     mySelectedUsers = [...this.state.selectedUsers];
            //     mySelectedUsers[assignment.id] = assignment.slackUserId;


            // }
            // this.setState({selectedUsers: mySelectedUsers})

            return (

                <div>
                    <Autocomplete options={this.state.users}
                                  autoHighlight
                                  value={this.valueInitialUser(this.state.changed[assignment.id].slackUserId)}
                                  getOptionLabel={(option) => option.firstName + ' ' + option.lastName}
                                  onChange={(event, option) => this.handleChange(event, option, assignment.id.toString())}
                                  renderOption={(props, option) => (
                                      <Box component="li" sx={{'& > img': {mr: 2, flexShrink: 0}}} {...props}>
                                          <img
                                              loading="lazy"
                                              width="20"
                                              src={imgUser}
                                              srcSet={imgUser}
                                              alt=""
                                          />
                                          {option.firstName} {option.lastName}
                                      </Box>
                                  )}
                                  renderInput={(params) => (
                                      <TextField
                                          {...params}
                                          label="Choose a user"
                                          inputProps={{
                                              ...params.inputProps,
                                              autoComplete: 'new-password', // disable autocomplete and autofill
                                          }}
                                          className="w-100"/>
                                  )}
                    />
                    {/*<FormControl sx={{m: 1, minWidth: 80}} onSubmit={this.saveChanges}>
                        <InputLabel id="demo-simple-select-label"/>
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={this.state.changed[assignment.id].slackUserId}
                            label={assignment}
                            name={assignment.id.toString()}
                            onChange={this.handleChange}
                            autoWidth
                        >

                            {this.state.users.map((us, i) => (
                                    <MenuItem divider="true" key={i} value={us.id}>{us.firstName + us.lastName}</MenuItem>
                                )
                            )}

                        </Select>
                    </FormControl>*/}
                </div>
            )

        }

    }

    userName(userId) {
        if (this.state.users != null) {
            for (let us of this.state.users) {
                if (us.id === userId) {
                    return <p>{us.firstName + us.lastName}</p>
                }
            }
        }
    }


    render() {
        console.log("STATE");
        console.log(this.state);

        return (
            <div>
                {this.props.user[0].stripe_subscriptions.length === 1 && this.props.user[0].stripe_subscriptions[0].quantity === 1 ?
                    <p>
                        You currently have 1 subscription available assigned to you. Purchase more subscriptions first
                        to
                        start asigning it to users in your org

                    </p> :
                    <div>
                        <Typography variant="h6" gutterBottom>
                            <b>Start assigning</b>
                        </Typography>
                        <Typography variant="body2" color="textSecondary" gutterBottom>
                            Please always leave 1 subscription assigned to you!
                        </Typography>

                        <br/>

                        {/*
              {this.displaySubscriptions()} */}

                        {this.displayAssignment()}

                        <div className="text-end">
                            <Link to="/" className="text-decoration-none">
                                <Button>Cancel</Button>
                            </Link>
                            <Button onClick={this.saveChanges} variant="contained">Save</Button>
                        </div>
                    </div>}

                <br>
                </br>
                {this.state.success ?
                    <Alert severity="success" className="alert-style">Successfully assigned subscriptions</Alert> : <p/>}
            </div>
        );
    }
}


// Map state to props;
const mapStateToProps = (state) => {
    console.log("state", state);
    return {
        user: state.users.user,
        selectedUser: state.users.selectedAccount
    };
};

const mapDispatchToProps = (dispatch) => {
    return {

        getUserSlack: (email) => dispatch(getUserThunk(email))

    };
};

export default connect(mapStateToProps, mapDispatchToProps)(AssignUsers);