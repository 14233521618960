import React from "react";
import "./Header.css";
import {Typography, IconButton} from "@mui/material";
import { Link } from "react-router-dom";
import { useIsAuthenticated, useMsal } from "@azure/msal-react";

import SignOutButton1 from "../../../azure_auth/components/CustomSignOut";
import {HomeOutlined} from "@mui/icons-material";

function Header() {
  const isAuthenticated = useIsAuthenticated();
  const { accounts } = useMsal();
  return (
    <div className="header">
      <div className="header_info">
        <Link to="/">
          <IconButton>
            <HomeOutlined fontSize="large" style={{ color: "pink" }}/>
          </IconButton>
        </Link>

        <Typography variant="h5" id="title">
          Moodbit | Employee Experience
        </Typography>
      </div>
      {/* <div className="header_search">
        <IconButton>
          <SearchIcon></SearchIcon>
        </IconButton>
        <input type="text" name="search" placeholder="search"></input>
      </div> */}
  
       
         {isAuthenticated ? <div className="header_right"><Typography style={{padding: "10px", fontSize: "1vmax"}}>Welcome, {accounts[0].name}!</Typography> <SignOutButton1 ></SignOutButton1> </div>: ""} 
      </div>
   
  );
}
export default Header;
