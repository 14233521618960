import imgEmployee from '../../assets/templates/employe engagement.png';
import imgEnps from '../../assets/templates/eNPS.png';
import imgEmploySatisfaction from '../../assets/templates/Employee Satisfaction Survey.png';
import imgAssessment from '../../assets/templates/Assessment of the incentive system and benefits.png';
import imgRelationship from '../../assets/templates/Relationship with Manager.png';
import imgStress from '../../assets/templates/Stress at work.png';
import {
    addJSONCardtoSurvey,
    addJSONtoSurvey,
    addNewAnswer,
    addNewQuestion,
    addNewResponse,
    addNewSurvey,
    addSchedule,
    deleteSchedule,
    deleteSurvey,
    getAllQuestionsBySurveyId,
    getAllSurveys,
    getAllSurveysWithSchedule,
    getAllTemplates,
    getSingleSurvey,
    getSingleSurveyWithQandA,
    getSingleTemplate
} from "./survey.actions";
import {
    addJSONCardtoSurveyService,
    addJSONtoSurveyService,
    addNewAnswerService,
    addNewQuestionService,
    addNewResponseService,
    addNewSurveyService, addScheduleService, deleteScheduleService, deleteSurveyService,
    getAllQuestionsBySurveyIdService,
    getAllSurveysService,
    getAllSurveysWithScheduleService,
    getSingleSurveyService, getSingleSurveysWithQandAService,
    getAllSurveysForUserService, getAllTemplatesService, getSingleTemplateService, addNewFullSurveyService
} from "../../services/survey.service";

export const addNewSurveyThunk = (obj) => {
    return async (dispatch) => {
        try {
            const data = await addNewSurveyService(obj);
            console.log("data", data);
            if (!data.error) dispatch(addNewSurvey(data));
        } catch (error) {
            console.error(error);
        }
    };
};

export const addNewQuestionThunk = (obj) => {
    return async (dispatch) => {
        try {
            const data = await addNewQuestionService(obj);
            console.log("data", data);
            if (!data.error) dispatch(addNewQuestion(data));
        } catch (error) {
            console.error(error);
        }
    };
};

export const addNewAnswerThunk = (obj) => {
    return async (dispatch) => {
        try {
            const { data } = await addNewAnswerService(obj);
            console.log("data", data);
            if (!data?.error) dispatch(addNewAnswer(data));
        } catch (error) {
            console.error(error);
        }
    };
};

export const addJSONtoSurveyThunk = (id, obj) => {
    return async (dispatch) => {
        try {
            const data = await addJSONtoSurveyService(id, obj);
            console.log("data", data);
            if (!data.error) dispatch(addJSONtoSurvey(data));
        } catch (error) {
            console.error(error);
        }
    };
};

//adds json for mf teams card
export const addJSONCardtoSurveyThunk = (id, obj) => {
    return async (dispatch) => {
        try {
            const data = await addJSONCardtoSurveyService(id, obj);
            console.log("data", data);
            if (!data.error) dispatch(addJSONCardtoSurvey(data));
        } catch (error) {
            console.error(error);
        }
    };
};

export const getAllSurveysThunk = () => {
    return async (dispatch) => {
        try {
            const data = await getAllSurveysService();
            console.log("data", data);
            if (!data.error) dispatch(getAllSurveys(data));
        } catch (error) {
            console.error(error);
        }
    };
};

export const getAllSurveysWithScheduleThunk = (tenantId) => {
    return async (dispatch) => {
        try {
            const data = await getAllSurveysWithScheduleService(tenantId);
            console.log("data", data);
            if (!data.error) dispatch(getAllSurveysWithSchedule(data));
        } catch (error) {
            console.error(error);
        }
    };
};

export const getAllUserSurveysThunk = (userId) => {
    return async (dispatch) => {
      try {
        const data = await getAllSurveysForUserService(userId);
        console.log("data", data);
        if (!data.error) dispatch(getAllSurveysWithSchedule(data));
      } catch (error) {
        console.error(error);
      }
    };
  };

export const getSingleSurveyThunk = (id) => {
    return async (dispatch) => {
        try {
            const data = await getSingleSurveyService(id);
            console.log("data", data);
            if (!data.error) dispatch(getSingleSurvey(data));
        } catch (error) {
            console.error(error);
        }
    };
};

export const addNewResponseThunk = (obj) => {
    return async (dispatch) => {
        try {
            const data = await addNewResponseService(obj);
            console.log("data", data);
            if (!data.error) dispatch(addNewResponse(data));
        } catch (error) {
            console.error(error);
        }
    };
};

export const getAllQuestionsBySurveyIdThunk = (id) => {
    return async (dispatch) => {
        try {
            const data = await getAllQuestionsBySurveyIdService(id);
            console.log("data", data);
            if (!data.error) dispatch(getAllQuestionsBySurveyId(data));
        } catch (error) {
            console.error(error);
        }
    };
};

export const getSingleSurveysWithQandAThunk = (id) => {
    return async (dispatch) => {
        try {
            const data = await getSingleSurveysWithQandAService(id);
            console.log("data", data);
            if (!data.error) dispatch(getSingleSurveyWithQandA(data));
        } catch (error) {
            console.error(error);
        }
    };
};

export const deleteSurveyThunk = (id) => {
    return async (dispatch) => {
        try {
            const data = await deleteSurveyService(id);
            console.log("data", data);
            if (!data.error) dispatch(deleteSurvey());
        } catch (error) {
            console.error(error);
        }
    };
};


//not deleting, but updating
export const deleteScheduleThunk = (id) => {
    return async (dispatch) => {
        try {
            const data = await deleteScheduleService(id);
            console.log("data", data);
            if (!data.error) dispatch(deleteSchedule());
        } catch (error) {
            console.error(error);
        }
    };
};

export const addScheduleThunk = (obj) => {
    return async (dispatch) => {
        try {
            const data = await addScheduleService(obj);
            console.log("data", data);
            if (!data.error) dispatch(addSchedule(data));
        } catch (error) {
            console.error(error);
        }
    };
};


export const getAllTemplatesThunk = () => {
    return async (dispatch) => {
        // dispatch(changeStatus('loading'));
        try {
            let data = await getAllTemplatesService();
            data = data.map((item, index) => {
                switch (index) {
                    case 0:
                        item.img = 'https://riskandinsurance.com/wp-content/uploads/2019/09/burnout-700x525.jpg';
                        break;
                    // case 1: item.img = 'https://www.openmet.com/wp-content/uploads/2016/02/nps-openmet.jpg';break;
                    case 1:
                        item.img = imgEnps;
                        break;
                    //case 2: item.img = 'https://s3-ap-southeast-1.amazonaws.com/s3-studilmu-ap-southeast-1.amazonaws.com/image/blogs/1539578130_tingkatkanemployeeengagement.jpg';break;
                    case 2:
                        item.img = imgEmployee;
                        break;
                    case 3:
                        item.img = imgEmploySatisfaction;
                        break;
                    case 4:
                        item.img = imgAssessment;
                        break;
                    case 5:
                        item.img = imgRelationship;
                        break;
                    case 6:
                        item.img = imgStress;
                        break;
                    default:
                        item.img = 'https://www.myhrtoolkit.com/hubfs/differences-employee-worker.jpg';
                        break;
                }
                return item;
            })
            console.log("dataTemplate", data);
            if (!data.error) {
                dispatch(getAllTemplates(data));
                // dispatch(changeStatus('loaded'));
            }
        } catch (error) {
            console.error(error);
        }
    };
};

export const getSingleTemplateThunk = (id) => {
    return async (dispatch) => {
        // dispatch(changeStatus('loading'));
        try {
            const data = await getSingleTemplateService(id);
            console.log("data", data);
            if (!data.error) {
                dispatch(getSingleTemplate(data));
                // dispatch(changeStatus('loaded'));
            }
        } catch (error) {
            console.error(error);
        }
    };
};


export const addNewFullSurveyThunk = (obj) => {
    return async (dispatch) => {
        // dispatch(changeStatus('loading'));
        try {
            const data = await addNewFullSurveyService(obj);
            console.log("data", data);
            if (!data.error) {
                dispatch(addNewSurvey(data));
                // dispatch(changeStatus('loaded'));
            }
        } catch (error) {
            console.error(error);
            // dispatch(errorSurvey(error));
        }
    };
};
