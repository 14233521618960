
import React, {Suspense} from "react";
import ReactDOM from "react-dom";
import "./index.css";
import 'bootstrap/dist/css/bootstrap.min.css'
import 'react-redux-toastr/lib/css/react-redux-toastr.min.css';
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import ReduxToastr from "react-redux-toastr";

import {BrowserRouter} from "react-router-dom"

//Redux
import { Provider } from "react-redux";
import store from "./redux/store";

//Azure auth
import { PublicClientApplication } from "@azure/msal-browser";
import { MsalProvider } from "@azure/msal-react";
import { msalConfig } from "./azure_auth/authConfig";
import * as microsoftTeams from "@microsoft/teams-js";
import {theme} from "./assets/styles/theme";
import './i18n';
import {CircularProgress, ThemeProvider} from "@mui/material";








//  microsoftTeams.initialize();
// let res = microsoftTeams.getContext(async function(context){
// console.log("TEAMS CONTEXT");
//   console.log(context);
//   console.log(context.sessionId);


//   var request = {
//     scopes: ["user.read"],
//    loginHint: context.loginHint
//   };

//   console.log("REDIRECTING WITH SID");
  
//   msalInstance.ssoSilent(request).catch((e) => {
//     console.log(e);

//   });

// })


const msalInstance = new PublicClientApplication(msalConfig);

ReactDOM.render(
  <React.StrictMode>
      <MsalProvider instance={msalInstance}>
      <Provider store={store}>
          <BrowserRouter>
          <ThemeProvider theme={theme}>
              <Suspense fallback={
                  <div className="text-center col-12">
                      <p>Loading...</p>
                      <CircularProgress/>
                  </div>
              }>
                  <App instance = {msalInstance}/>
              </Suspense>
          </ThemeProvider>
          </BrowserRouter>
          <div>
              <ReduxToastr
                  timeOut={4000}
                  newestOnTop={false}
                  preventDuplicates
                  position="top-right"
                  getState={(state) => state.toastr} // This is the default
                  transitionIn="fadeIn"
                  transitionOut="fadeOut"
                  closeOnToastrClick/>
          </div>
      </Provider>
      </MsalProvider>
  </React.StrictMode>,
  document.getElementById("root")
);

//ttt
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
