import employee from "../../assets/employee.gif";
import './home.css';
import {Alert, Avatar, Button, capitalize, CircularProgress, Snackbar} from "@mui/material";
import {
    AuthenticatedTemplate,
    MsalContext,
    UnauthenticatedTemplate,
    useIsAuthenticated,
    useMsal
} from "@azure/msal-react";
// import {SignInButton} from "../../auth/ui";
import React, {Component, useEffect, useState} from "react";
import SurveySlackIndex from "../survey/survey-index/survey-slack-index";
import {connect} from "react-redux";
import {addUserThunk, getUserThunk} from "../../redux/users/users.thunk";
import {useTeamsFx} from "../../lib/useTeamsFx";
import {useGraph} from "../../lib/useGraph";
import {loginRequest} from "../../azure_auth/authConfig";
import * as microsoftTeams from "@microsoft/teams-js";
import {SignInButton} from "../../azure_auth/components/SignInButton";
import teamsLogo from '../../assets/Microsoft_Office_Teams.png';

import { callMsGraph } from "../../azure_auth/graph";
const HomePage = (props) => {

    //const isAuthenticated = useIsAuthenticated();
    const {accounts, instance, inProgress} = useMsal();

    console.log("ACCOUNTS: ");
    console.log(accounts);


    const {isInTeams, context} = useTeamsFx();


    const {reload} = useGraph();

    console.log("CONTEXT");
    console.log(context);

    console.log("SESSION STORAGE");
    console.log(window.sessionStorage);

    const [loginRequired, setLoginRequired] = useState(false);

    async function getUserProfile() {
        // Silently acquires an access token which is then attached to a request for MS Graph data
        if (accounts.length > 0) {
            console.log("Exist account", accounts)
            instance
                .acquireTokenSilent({
                    ...loginRequest,
                    account: window.sessionStorage,
                })
                .then((response) => {

                    console.log("TOKEN ACQUIRED");
                    console.log(response);

                    if(props.user === null || props.user === undefined){
                        console.log("NULL USER")

                        callMsGraph(response.accessToken).then((newResponse) => {
                            console.log("NEW RESPONSE USERs", newResponse)
                            let user = {
                                lastName: newResponse.surname,
                                firstName: newResponse.givenName,
                                email: accounts[0].username,
                                id: response.uniqueId,
                                tenantId: accounts[0].tenantId
                            };

                            props.addUser(user).then(()=>console.log("user added to db"))





                        });

                    } else {
                        console.log("USEr", props.user)
                    }


                }).catch(function (error) {
                console.log("ERROR WHEN ACQUIRING");
                console.log(error);
                // if (error instanceof InteractionRequiredAuthError){
                //   console.log("INTERACTION REQUIRED");
                //   setLoginRequired(true);

                // }
                setLoginRequired(true);
            })
        }
    };

    function isIOs() {
        if (window.navigator.appVersion.includes("iOS")) {
            console.log("yes");
            return true;
        } else
            return false;
    }


    function isTeamsTab() {
        //console.log('NAVIGATION', window.navigator.appVersion);
        //if (window.navigator.appVersion.includes("Teams")) {
        console.log('CTX TEAMS', context);
        if (context !== undefined) {
            return true;
        } else
            return false;

    }


    function signIn() {
        console.log("teams signin");


        microsoftTeams.authentication.authenticate({
            url: window.location.origin + "/auth-start.html",
            //url: "msauth.ee2d-71-247-250-157.ngrok.io://auth-start.html",
            width: 600,
            height: 535,
            successCallback: function (result) {
                //getUserProfile(result.accessToken);


                instance.ssoSilent({loginHint: context.loginHint})
                setLoginRequired(false);


            },
            failureCallback: function (reason) {

                reload();
                //handleAuthError(reason);
            }
        });

        // var authTokenRequest = {
        //   successCallback: function(result) {
        //     console.log("Success: " + result);
        //     instance.ssoSilent({loginHint: context.loginHint})

        // },
        //   failureCallback: function(error) { console.log("Failure: " + error); }
        // };
        // microsoftTeams.authentication.getAuthToken(authTokenRequest);

    }

    getUserProfile();

    const {t} = props;

    return (
        <div>
            <AuthenticatedTemplate>
                <div>
                    {loginRequired && !isTeamsTab() ?
                        <div>
                            <p>Your sessions has expired, please Sign In again.</p>
                            <SignInButton variant="outlined"
                                                startIcon={<Avatar src={teamsLogo} alt="teams" sx={{ width: 24, height: 24 }}/>}
                                                color="primary"
                                                size="large" className="mt-2"/>
                            
                        </div> :
                        <div>
                            {/*this.context.accounts.length > 0 ?
                                        <div>
                                            <Chip
                                                color="success"
                                                label={`Your team id: ` + this.context.accounts[0].idTokenClaims.extension_teamId}
                                                icon={<Key/>}
                                                variant="outlined"
                                            />

                                            {this.props.user[0].admin ?
                                                <div className="text-center">
                                                    <CustomerPortal email={this.context.accounts[0].username}/>
                                                    <Link to={{
                                                        pathname: "/assignSubscriptions",
                                                        state: {email: this.context.accounts[0].username}
                                                    }} className="text-decoration-none"><Button variant="contained">Assign
                                                        Subscriptions</Button></Link>

                                                </div> : <p/>}
                                        </div>
                                        : <p/>*/}
                            <SurveySlackIndex/>
                        </div>
                    }
                </div>
            </AuthenticatedTemplate>
            <UnauthenticatedTemplate>
                {inProgress !== 'none' ?
                    inProgress === 'login' || inProgress === 'logout' ?
                        <div className="text-center">
                            <p>{`${capitalize(inProgress)} in progress...`}</p>
                            <CircularProgress/>
                        </div> : null :
                    <div className="row h-100 mt-5">
                        <div className="col-md-6">
                            <p className="fs-lg-1">
                                <b>{t("home.title")}</b>
                            </p>
                            <p className="fs-5">{t('home.description')}</p>
                            {/*<p className="fs-lg-1">
                                    <b>Bring out the best in your <span className="underline">team.</span></b>
                                </p>
                                <p className="fs-5">A single platform to engage with your team members and
                                    cultivate trust. Tackle challenges and build strengths so your team can do its best
                                    work.</p>*/}
                            {/*<Button variant="contained" color="primary" size="large"
                                        className="mt-2">Get Started</Button>*/}
                            {isTeamsTab() ?
                                <Button variant="outlined" color="primary" size="large" className="mt-2" onClick={() => signIn()}>Sign In For Teams</Button>
                                : <SignInButton variant="outlined"
                                                startIcon={<Avatar src={teamsLogo} alt="teams" sx={{ width: 24, height: 24 }}/>}
                                                color="primary"
                                                size="large" className="mt-2"/>}
                            {/*<Button variant="outlined" color="primary" size="large" className="mt-2" onClick={() => signIn()}>Sign In</Button>*/}

                        </div>
                        <div className="col-md-6 text-center">
                            <img width="100%" src={employee} alt=""/>
                        </div>
                    </div>}
            </UnauthenticatedTemplate>

            <div>
                {props.lastUser ?
                    <Alert severity="success" sx={{width: '100%'}}>
                        Success!, Moodbit Pulse was successfully installed in your workspace
                    </Alert> : null
                }
            </div>
        </div>
    );

}

// Map state to props;
const mapStateToProps = (state) => {
    console.log("state", state);
    return {
        user: state.users.user,
        lastUser: state.users.lastUser,
        selectedUser: state.users.selectedAccount
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        getUserSlack: (id) => dispatch(getUserThunk(id)),
        addUser: (obj)=>dispatch(addUserThunk(obj))
        // getUserSlackLastTime: () => dispatch(getUserSlackLastTimeThunk())
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(HomePage);
