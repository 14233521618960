import React, { Component } from 'react';
import { PowerBIEmbed } from 'powerbi-client-react';
import { models, Report, Embed, service, Page } from 'powerbi-client';
import "./ResponsesReport.css";
import { ContactsOutlined, ThreeSixtySharp } from '@mui/icons-material';
import Header from "../OldComponents/Header/Header";
import axios from 'axios';
import {connect} from "react-redux";
import { MsalContext, AuthenticatedTemplate, UnauthenticatedTemplate } from "@azure/msal-react";

import {getSingleSurveysWithQandAThunk} from "../../redux/survey/survey.thunk";
import {Link} from "react-router-dom";
import {MutatingDots} from "react-loader-spinner";
import {palette} from "../../assets/styles/theme";
import {Button} from "@mui/material";


// let filter = [
//   {
//     $schema: "http://powerbi.com/product/schema#advanced",
//     target: 
//     { table: "public slack_questions", 
//     column: "slack_survey_id" }, 
//     filterType: 0, 
//     logicalOperator: "And", 
//     conditions: [{ operator: "Is", value: 34 }]
//   }];


class ResponsesReport extends Component {


  state = {
    id: null,
    config: null,
    loading: true
    
  }

  async componentDidMount(){
    console.log(window);

    console.log(window.innerWidth);
    console.log(window.innerHeight);
    console.log("calling backend");
    let res = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/pbi/getEmbedInfoTeams`);
    //let res = await axios.get("http://localhost:8080/api/pbi/getEmbedInfoTeams");
    console.log(res);

    let config =   {
      type: 'report', // Supported types: report, dashboard, tile, visual, and qna.
      id: res.data.embedUrl[0].reportId,
      embedUrl: res.data.embedUrl[0].embedUrl,
      accessToken: res.data.accessToken,
      tokenType: models.TokenType.Embed, // Use models.TokenType.Aad if you're embedding for your organization.
      settings: {
        panes: {
          filters: {
            expanded: false,
            visible: false
          }
        },
        visualSettings: {
          visualHeaders: [
              {
                  settings: {
                      visible: false
                  }
                  /* No selector - Hide visual header for all the visuals in the report */
              }
          ]
      },
      

        layoutType: models.LayoutType.Custom,
        customLayout: {
         displayOption: models.DisplayOption.FitToPage
        }
      }
    };

    if(window.innerWidth < window.innerHeight){
      console.log("MOBILE")
      config.settings.layoutType = models.LayoutType.MobilePortrait
      delete config.settings.customLayout;
    }

    this.setState({config, loading: false });

      const path = window.location.pathname.split('/')
      const id = path[path.length - 1];
    await this.props.getSingleSurvey(id);
    console.log('SURVEYYY', this.props.singleSurvey);
  }







  // componentDidMount(){
  //   this.setState
  // }

setFilters = async ()=>{
  
      console.log('Report loaded');
      let activePage = await window.report.getActivePage();



      console.log(activePage);
      let filter = await activePage.getFilters();
    const path = window.location.pathname.split('/')
    const id = path[path.length - 1]
       filter[0].conditions[0].value = id;
      console.log(filter);

      console.log(window.location);


      try {
      await activePage.updateFilters(models.FiltersOperations.Replace, [filter[0]]);
console.log("Page filters were replaced.");
      }
      catch(error){
        console.log(error);
      }


      const visuals = await activePage.getVisuals();

      console.log("VISUALS");
      console.log(visuals);

       
    // Retrieve the target visual.
    let slicer = visuals.filter(function (visual) {
        return visual.type === "slicer";
    })[0];

    // Get the slicer state
    let state = await slicer.getSlicerState();

    console.log(state);
  
    state.filters[0].values=[this.props.singleSurvey.questions[0].content];

    await slicer.setSlicerState({ filters: [state.filters[0]] });

    console.log(slicer);
   console.log("Date slicer was set.");

  

    
  }



  render() {

    console.log(this.props);
    return (
      <div>

        <AuthenticatedTemplate>
            <div className="py-3 text-end">
                <Link to={`/surveys`} style={{textDecoration: 'none'}}>
                    <Button variant="contained">Back</Button>
                </Link>
            </div>

            <div className="d-flex justify-content-center">
                <MutatingDots
                    color={palette.primary}
                    height="100"
                    width="100"
                    visible={this.state.loading}/>
            </div>

        {this.state.loading ? <p className="text-center">Loading...</p> :
        <PowerBIEmbed

      
          embedConfig={this.state.config
          }

          eventHandlers={new Map([
            ['loaded', this.setFilters],
            ['rendered', function () {
              console.log('Report rendered');
            }],
            ['error', function (event) {
              console.log(event.detail);
            }]
          ])
          }

          cssClassName={
            "report-style-class"
          }

          getEmbeddedComponent={
            (embeddedReport) => {
              window.report = embeddedReport;
            }
          }

        />
  }

  </AuthenticatedTemplate>
  <UnauthenticatedTemplate>Please sign-in to view this page</UnauthenticatedTemplate>
      </div>
    )
  }

}


// Map state to props;
const mapStateToProps = (state) => {
  console.log("state", state);
  return {
    allSurveys: state.surveys.allSurveys,
    singleSurvey: state.surveys.singleSurvey,
    
  };
};

// Map dispatch to props;
const mapDispatchToProps = (dispatch) => {
  return {
    // getAllSurveysWithScheduleSlack: (teamId) => dispatch(getAllSurveysWithScheduleSlackThunk(teamId)),
    getSingleSurvey: (id)=>dispatch(getSingleSurveysWithQandAThunk(id))
    
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ResponsesReport);
