import { Question } from "../../models/Question";
import { Survey } from "../../models/Survey";
import { Dimension } from "../../models/Dimension";
import { QuestionsConverter } from "./questionsConverter";
import questionsTemplate from "./questionsTemplate.json";

export class SurveyConverter {
  protected survey: Survey;
  protected questions = [] as Question[];

  protected questionsConverter: QuestionsConverter;

  constructor(
    protected rawDataFull: any,
    protected rawDataWithR: any,
    protected variant: "native" | "teams"
  ) {
    this.survey = {
      id: rawDataFull.id,
      title: rawDataFull.title,
      dimensions: [] as Dimension[],
      numberOfResponses: rawDataFull.numberOfResponses,
      templateName: "",
    };

    this.questionsConverter = new QuestionsConverter(
      this.rawDataFull,
      this.rawDataWithR,
      variant
    );
  }

  public getSurvey(): Survey {
    return this.survey;
  }

  public getQuestions(): Question[] {
    return this.questions;
  }

  protected getDimensionsData(): void {
    this.questionsConverter.generateQuestions();
    const questions: Question[] = this.questionsConverter.getQuestions();

    //get dimensions names
    let dimensions = [] as Dimension[];
    if (this.rawDataFull.json.pages !== undefined) {
      for (const page of this.rawDataFull.json.pages) {
        // dimensions.push({ name: page.title } as Dimension);
        dimensions.push({ name: page.name } as Dimension);
      }
    } else {
      for (const question of questions) {
        if (
          dimensions.find(
            (dimension) => question.dimension === dimension.name
          ) === undefined
        ) {
          dimensions.push({ name: question.dimension } as Dimension);
        }
      }
    }
    console.log("dimensions:", dimensions);
    this.survey.dimensions = dimensions;

    // get survey name without "Survey" and numbers
    for (const templateKey of Object.keys(questionsTemplate)) {
      console.log(templateKey);
      if (
        this.survey.title
          .replace(/\(.*\)/i, "") // delete the parentheses
          .match(templateKey.replace(/\(.*\)/i, "")) !== null
      ) {
        this.survey.templateName = templateKey.replace("Survey", "");
      }
    }

    let i = 0;
    for (const dimension of this.survey.dimensions) {
      let scoreSum = 0;
      let maxScoreSum = 0;
      let dimensionQuestions: Question[] = questions.filter(
        (question) => question.dimension === dimension.name
      );
      let number = dimensionQuestions.length;
      for (const question of dimensionQuestions) {
        scoreSum += question.score;
        maxScoreSum += question.maxScore;
      }
      this.survey.dimensions[i].score = scoreSum / number;
      this.survey.dimensions[i].maxScore = maxScoreSum / number;
      i++;
    }
  }

  public generateSurvey(): void {
    if (this.variant === "teams") {
      this.getDimensionsData();
    }
  }
}

// survey types: emps
