import axios from "axios";

export const getUserService = async (id) => {
    const {data} = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/users/userByEmail/${id}`);
    return data;
}

export const addUserService = async (obj) => {
    const {data} = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/users/newUser`, obj);
    return data;
}