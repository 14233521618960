import axios from "axios";
import {
    addJSONCardtoSurveyThunk,
    addJSONtoSurveyThunk,
    addNewResponseThunk, deleteSurveyThunk,
    getAllQuestionsBySurveyIdThunk, getSingleSurveysWithQandAThunk
} from "../redux/survey/survey.thunk";

export const addNewSurveyService = async (obj) => {
    const { data } = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/surveys/newSurvey`, obj);
    return data;
}

export const addNewFullSurveyService = async (obj) => {
    console.log("FULL SURVEY", obj)
    const { data } = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/surveys/newFullSurvey`, obj);
    return data;
}

export const addNewQuestionService = async (obj) => {
    const { data } = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/questions/newQuestion`, obj);
    return data;
}

export const addNewAnswerService = async (obj) => {
    const { data } = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/answers/newAnswer`, obj);
    return data;
}

export const addJSONtoSurveyService = async (id, obj) => {
    const { data } = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/surveys/newSurvey/${id}`, obj);
    return data;
}

export const addJSONCardtoSurveyService = async (id, obj) => {
    const { data } = await axios.put(`${process.env.REACT_APP_BACKEND_URL}/api/surveys/newSurvey/mfcard/${id}`, obj);
    return data;
}

export const getAllSurveysService = async () => {
    const { data } = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/surveys/allSurveys`);
    return data;
}

export const getAllSurveysForUserService = async (userId) =>{
    const { data } = await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}/api/surveys/allUserSurveys/${userId}`
      );
     return data;
}

export const getAllSurveysWithScheduleService = async (tenantId) => {
    const { data } = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/surveys/allSurveysWithSchedule/${tenantId}`);
    return data;
}

export const getSingleSurveyService = async (id) => {
    const { data } = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/surveys/singleSurvey/${id}`);
    return data;
}

export const addNewResponseService = async (obj) => {
    const { data } = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/responses/newResponse`, obj);
    return data;
}

export const getAllQuestionsBySurveyIdService = async (id) => {
    const { data } = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/questions/allQuestions/${id}`);
    return data;
}

export const getSingleSurveysWithQandAService = async (id) => {
    const { data } = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/surveys/singleSurveyFull/${id}`);
    return data;
}

export const deleteSurveyService = async (id) => {
    const { data } = await axios.delete(`${process.env.REACT_APP_BACKEND_URL}/api/surveys/deleteSurvey/${id}`);
    return data;

}

export const deleteScheduleService = async (id) => {
    const { data } = await axios.put(`${process.env.REACT_APP_BACKEND_URL}/api/schedules/editSchedule/${id}`, {state: false});
    return data;

}

export const addScheduleService = async (obj) => {
    const { data } = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/schedules/newSchedule`, obj);
    return data;
}

export const getAllTemplatesService = async () => {
    const { data } = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/surveys/templates`);
    return data;
}

export const getSingleTemplateService = async (id) => {
    const { data } = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/surveys/templates/${id}`);
    return data;
}

