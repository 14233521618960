import {Avatar, Divider, IconButton, List, ListItem, ListItemAvatar, ListItemText} from "@mui/material";
import {Send} from "@mui/icons-material";
import {useNavigate} from "react-router-dom";

const StartTemplateList = (props) => {

    const navigate = useNavigate();

    const {templates} = props;

    return (
        <List className="w-100 bg-white">
            {templates.map((item, index) =>
                <div key={index}>
                    <ListItem secondaryAction={
                        <IconButton edge="end" aria-label="send"
                                    onClick={() => navigate(`/survey-create/start-template/${item.id}`)}>
                            <Send/>
                        </IconButton>}>
                        <ListItemAvatar>
                            <Avatar alt="" src={`${process.env.REACT_APP_BACKEND_URL}/api/surveys/template-image-download/${item.image}/template-teams`}/>
                        </ListItemAvatar>
                        <ListItemText primary={item.title}
                                      secondary={item.description}/>
                    </ListItem>
                    {templates.length - 1 !== index ? <Divider variant="inset" component="li"/> : null}
                </div>
            )}
        </List>
    )
}

export default StartTemplateList;
