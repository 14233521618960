import axios from "axios";
import { Question } from "../models/Question";
import { QuestionsConverter } from "../utils/data-conversion/questionsConverter";
// import { SurveyFaker } from "../utils/surveyFaker/surveyFaker";

export class QuestionService {
  private static singleSurveyFullURL: string =
    "https://survey-app-backend.azurewebsites.net/api/surveys/singleSurveyFull";
  private static singleSurveyWithRURL: string =
    "https://survey-app-backend.azurewebsites.net/api/surveys/singleSurveyWithR";

  public static async getAllQuestionsOfSurvey(
    surveyId: string
  ): Promise<Question[]> {
    let questions = [] as Question[];

    let singleSurveyFull: any = (
      await axios.get(this.singleSurveyFullURL + "/" + surveyId)
    ).data;
    let singleSurveyWithR: any = (
      await axios.get(this.singleSurveyWithRURL + "/" + surveyId)
    ).data;

    const questionsConverter = new QuestionsConverter(
      singleSurveyFull,
      singleSurveyWithR,
      "teams"
    );
    questionsConverter.generateQuestions();
    questions = questionsConverter.getQuestions();

    // questions = SurveyFaker.generateQuestions(10);

    console.log("questions:", questions);

    return questions;
  }

  /*
  public static async getQuestion(id: string): Promise<Question> {
    return {} as Question;
  }
  */
}
