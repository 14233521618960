import './App.css';
import AppRouting from "./app-routing";
import NavBar from "./components/layouts/NavBar";
import {Box, CircularProgress, Container, Toolbar} from "@mui/material";
import {MsalContext, useIsAuthenticated, useMsal} from "@azure/msal-react";
import axios from "axios";
import React, {Component, memo} from "react";
import {connect} from "react-redux";
import {getUserThunk} from "./redux/users/users.thunk";

const App = memo(class extends Component {

    state = {
        loading: false,
        subscriptions: []
    }

    static contextType = MsalContext;

    componentDidMount() {
        if (this.props.user) {
            this.setState({loading: false});
        } else {
            if (this.context.accounts.length > 0) {
                this.setState({loading: true});
            }
        }
        // this.props.getUserSlackLastTime();

    }

    async componentDidUpdate() {
        console.log("updated");
        if (this.props.user) {
            return
        }
        if (this.context.accounts.length > 0) {
            console.log("getting user");
            await this.props.getUserSlack(this.context.accounts[0].username);
            // let subs = await axios.get(`https://survey-app-backend.azurewebsites.net/api/stripe/subscriptions/${this.context.accounts[0].username}`);
            // console.log(subs.data);
            // this.setState({ subscriptions: subs.data });
            /*if (this.props.user[0].admin === true) {
                if (this.props.user[0].ownedSubscriptions.length < 1) {

                    let obj = {
                        email: this.props.user[0].email,
                        stripeCustomerId: this.props.user[0].stripeCustomerId,
                        type: "standard"
                    };
                    let res = await axios.post("https://survey-app-backend.azurewebsites.net/api/stripe/create-checkout-session", obj);
                    console.log(res.data);
                    window.location.href = res.data;
                    // this.setState({ loading: false });
                }
            }*/
            console.log("GET User", this.props.user);
            this.setState({loading: false});
        }
        console.log('LOCAL STORAGE', localStorage.getItem('slackTeamId'));
    }

    render() {
        console.log('render APP')
        return (
            <div className={`min-vh-100 ${this.context.accounts.length === 0 ? this.context.inProgress === 'logout' ? '' : 'bg-light' : ''}`}>
                <NavBar/>
                <Box className="h-100">
                    <Toolbar/>
                    <Container className="mt-md-5">
                        <div className="mx-md-5">
                            {this.state.loading || this.props.userStatus === 'loading' ?
                                <div className="text-center col-12">
                                    <p>Loading...</p>
                                    <CircularProgress/>
                                </div>
                                : <AppRouting/>}
                        </div>
                    </Container>
                </Box>

            </div>
        );
    }
});

// Map state to props;
const mapStateToProps = (state) => {
    console.log("state App", state);
    return {
        user: state.users.user,
        selectedUser: state.users.selectedAccount,
        userStatus: state.users.status
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        getUserSlack: (email) => dispatch(getUserThunk(email)),
        // getUserSlackLastTime: () => dispatch(getUserLastTimeThunk())
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
