import { Context } from "chartjs-plugin-datalabels";
import { Grid, Paper, Table, Typography } from "@mui/material";
import { experimentalStyled as styled } from "@mui/material/styles";
import { Doughnut } from "react-chartjs-2";
import { TextQuestion } from "../../models/Question";
import { SentimentBar } from "../ui";

// import { ProgressBar } from "react-bootstrap"; // DELETE THIS
// import { MyMatrix, MyProgressBar } from '../../../../helpers/funtionsUtils/funtionsUtils';

const Item = styled(Paper)(({ theme }) => ({
  ...theme.typography.body2,
  padding: theme.spacing(1),
  marginBottom: theme.spacing(3),
  color: theme.palette.text.primary,
  width: "100%",
  height: "auto",
}));

const containerStyle = {
  display: "flex",
  justifyContent: "center",
};

interface Props {
  index: number;
  question: TextQuestion;
}

function calculateChoiceValues(question: any): number[] {
  let choiceValues = [0, 0, 0];
  console.log("question: ", question);
  if (question !== undefined) {
    for (const x of question.sentimentScores) {
      if (x > 0.33) {
        if (x > 0.66) choiceValues[2]++;
        else choiceValues[1]++;
      } else choiceValues[0]++;
    }
  }
  console.log("choise: ", choiceValues);
  return choiceValues;
}

export function Type2QuestionChart({ index, question }: Props): JSX.Element {
  /*
  const doughnutData = {
    labels: ["Red", "Blue", "Yellow", "Green", "Purple", "Orange"],
    datasets: [
      {
        label: "# of Votes",
        data: [12, 19, 3, 5, 2, 3],
        borderWidth: 1,
      },
    ],
  };

  const doughnutOptions = {
    scales: {
      y: {
        beginAtZero: true,
      },
    },
  };
  */

  const doughnutData = {
    labels: ["negative", "neutral", "positive"],
    datasets: [
      {
        label: "",
        data: calculateChoiceValues(question),
        borderWidth: 1,
        backgroundColor: ["#FB5523", "#FDD835", "#6DAE43"],
        /*
        plugins:{
          datalabels: {
          formatter: function(value : any, context : any) {
              return Math.round((value/calculateChoiceValues(question).reduce((a:any, b:any) => a + b, 0))*100) + '%';
          }
        }
        */
      },
    ],
  };

  const doughnutOptions = {
    scales: {
      y: {
        beginAtZero: true,
      },
    },
    plugins: {
      datalabels: {
        color: "#111",
        textAlign: "center",
        font: {
          lineHeight: 1.6,
        },
        formatter: (value: number, ctx: Context) => {
          if (ctx.chart.data.labels && value > 0) {
            return (
              ctx.chart.data.labels[ctx.dataIndex] +
              "\n" +
              ((value / question.answers.length) * 100).toFixed(2) +
              "%"
            );
          } else if (value === 0) {
            return "";
          }
        },
      },
    },
  };

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12} md={9}>
          <Item>
            <Typography
              fontSize={"18px"}
              fontFamily={"IBM Plex Sans"}
              fontWeight={"SemiBold"}
              color={"#1A237E"}
            >
              {index}. {question.title}
            </Typography>
            <Table
              // striped bordered hover
              className="w-100"
            >
              <thead>
                <tr>
                  <th>Answers</th>
                  <th>Sentiment</th>
                </tr>
              </thead>
              <tbody>
                {question.answers.map((answer, index) => {
                  const sentimentScore = question.sentimentScores[index];
                  return (
                    <tr key={index}>
                      <td className="height-plus">
                        {index + 1}. {answer}
                      </td>
                      <td>
                        {/*
                          <ProgressBar
                            variant={
                              sentiment > 0.33
                                ? sentiment > 0.66
                                  ? "success"
                                  : "warning"
                                : "danger"
                            }
                            now={100}
                            label={
                              sentiment > 0.33
                                ? sentiment > 0.66
                                  ? "POSITIVE"
                                  : "NEUTRAL"
                                : "NEGATIVE"
                            }
                            className="center"
                          />
                          */}
                        <SentimentBar score={sentimentScore} />
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </Table>
          </Item>
        </Grid>
        <Grid item xs={12} md={3}>
          <Item>
            <Typography
              fontSize={"18px"}
              fontFamily={"IBM Plex Sans"}
              fontWeight={"SemiBold"}
              color={"#1A237E"}
            >
              Satisfaction
            </Typography>
            <p>Team feelings</p>
            <Doughnut
              // @ts-ignore
              options={doughnutOptions}
              data={doughnutData}
            />
            <br />
            <div className="" style={containerStyle}>
              {/*
                <MyProgressBar value={1} text="POSITIVE" rounded={false} />
                <MyProgressBar value={0.5} text="NEUTRAL" rounded={false} />
                */}
            </div>
            <div className="" style={containerStyle}>
              {/*
                <MyProgressBar value={0.1} text="NEGATIVE" rounded={false} />
                */}
            </div>
          </Item>
        </Grid>
      </Grid>
    </>
  );
}
