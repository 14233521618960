import React, { Component } from "react";
import "survey-react/survey.css";
import * as Survey from "survey-react";

import { connect } from "react-redux";
import { getSingleSurveyThunk } from "../redux/survey/survey.thunk";
import { AuthenticatedTemplate, UnauthenticatedTemplate } from "@azure/msal-react";

import Header from "./OldComponents/Header/Header";
import {Link} from "react-router-dom";
import {Button, CircularProgress} from "@mui/material";

const jsonFile = {
  title: "Web Development Survey",
  questions: [
    {
      name: "Are you a web developer?",
      type: "radiogroup",
      choices: ["Yes", "No"],
    },
    {
      name: "What technologies have you used?",
      type: "checkbox",
      choices: ["React", "Redux", "Node.js", "Javascript"],
    },
  ],
  description: "Most used tech-stack",
};

class DisplaySurvey extends Component {
  state = {
    json: undefined,
    loaded: false,
  };

  async componentDidMount() {
    // let id = this.props.match.params.id;
    const path = window.location.pathname.split('/')
    const id = path[path.length - 1];
    await this.props.getSingleSurvey(id);
    this.setState({ json: this.props.singleSurvey.json, loaded: true });
  }

  componentDidUpdate() {
 
  }

  render() {
    return (
      <div>
        <AuthenticatedTemplate>
          <div className="py-3 text-end">
            <Link to={`/surveys`} style={{textDecoration: 'none'}}>
              <Button variant="contained">Back</Button>
            </Link>
          </div>
        {!this.state.loaded && this.state.json === undefined ? (
            <div className="text-center">
              <CircularProgress/>
            </div>
        ) : (
          <div>
            <Survey.Survey
              json={this.state.json}
              showNavigationButtons={false}
              mode="display"
            />
          </div>
        )}
        </AuthenticatedTemplate>
        <UnauthenticatedTemplate>Please Sign In to View the Dashboard</UnauthenticatedTemplate>
      </div>
    );
  }
}

// Map state to props;
const mapStateToProps = (state) => {
  console.log("state", state);
  return {
    singleSurvey: state.surveys.singleSurvey,
  };
};

// Map dispatch to props;
const mapDispatchToProps = (dispatch) => {
  return {
    getSingleSurvey: (id) => dispatch(getSingleSurveyThunk(id)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(DisplaySurvey);
