import React, {Component} from 'react';
import axios from "axios";
import {MutatingDots, ThreeDots} from "react-loader-spinner";
import "./DateTimePicker.css";
import {connect} from "react-redux";
import {MsalContext} from '@azure/msal-react';
import moment from 'moment';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    FormGroup,
    IconButton,
    Menu,
    MenuItem,
    TextField,
    ToggleButton,
    ToggleButtonGroup,
    Tooltip,
    Typography
} from "@mui/material";
import {LocalizationProvider} from '@mui/x-date-pickers/LocalizationProvider';
import {StaticTimePicker} from '@mui/x-date-pickers/StaticTimePicker';
import {AdapterDateFns} from "@mui/x-date-pickers/AdapterDateFns";
import {AutoDelete, History, Schedule} from "@mui/icons-material";
import {
    addScheduleThunk,
    deleteScheduleThunk,
    getAllSurveysWithScheduleThunk,
    getAllUserSurveysThunk
} from "../../../../redux/survey/survey.thunk";
import {getUserThunk} from "../../../../redux/users/users.thunk";
import {loginRequest} from "../../../../azure_auth/authConfig";
import {callMsGraph} from "../../../../azure_auth/graph";
import {palette} from "../../../../assets/styles/theme";


class SlackDateTimePicker extends Component {

    static contextType = MsalContext;

    state = {
        isOpen: false,
        openMenu: null,
        type: "SCHEDULE",
        //0 - 6, Sunday - Saturday
        days: [false, false, false, false, false, false, false],
        myDays: [],
        time: "09:00",
        dateTime: new Date().setHours(9, 0, 0),
        sending: false,
        success: false,
        userId: '',
        message: '',
        hasDay: false,
        hasSubmit: false
    }

    //let teamId = this.context.accounts[0].idTokenClaims.extension_teamId;  TODO change to this

    componentDidMount() {
        if (this.props.scheduleId) {
            this.setState({type: "EDIT"});
        }
        this.getUserProfile();
    }

    getUserProfile = async () => {
        let id;
        // Silently acquires an access token which is then attached to a request for MS Graph data
        this.context.instance
            .acquireTokenSilent({
                ...loginRequest,
                account: window.sessionStorage,
            })
            .then((response) => {
                callMsGraph(response.accessToken).then((response) => {

                    this.setState({userId: response.id});

                });
            })
            .catch(function (e) {
                // console.log(e);
            })


    };

    handleClickOpen = () => {
        this.setState({isOpen: true});
        if (this.props.days) {
            const newDays = [];
            this.props.days.map((item, index) => {
                if (item) {
                    newDays.push(index.toString())
                }
            });
            this.setState({days: this.props.days, myDays: newDays});

        }

        if (this.props.hours) {

            let myMinutes = this.props.minutes;
            let myHours = this.props.hours;
            if (this.props.minutes < 10) {
                myMinutes = "0" + this.props.minutes;
            }

            if (this.props.hours < 10) {
                myHours = "0" + this.props.hours;
            }

            let myTime = myHours + ":" + myMinutes;


            this.setState(
                {time: myTime, dateTime: new Date().setHours(myHours, myMinutes, 0)});
        }
    };

    handleClose = () => {
        this.setState({isOpen: false});
        this.setState({success: false});
    };

    handleDayChange = (e) => {
        let newDays = [...this.state.days];

        newDays[e.target.name] = !this.state.days[e.target.name];
        this.setState({days: newDays});


    }

    handleDays = (e, days) => {

        this.setState({myDays: days});
        let newDays = [false, false, false, false, false, false, false];
        days.map(item => {
            newDays[item] = true;
        });
        this.setState({days: newDays, hasDay: days.length > 0});
    }

    handleTimeChange = (e) => {
        this.setState({dateTime: new Date(e), time: moment(e).format('HH:mm')});
    }

    handleSubmit = async () => {

        console.log("SUBMIT");
        this.setState({
            hasSubmit: true
        })

        if (this.state.hasDay) {
            //no way to edit a durable function running instance so we have to delete it and create a new one
            if (this.state.type === "EDIT") {


                //need to delete an instance from function app
                await this.handleDelete(this.props.scheduleId, "edits");


                //need to delete from db
            }

            let myTime = this.state.time;
            let str = myTime.split(':');

            // let teamId = this.props.selectedTeam;

            let obj = {
                days: this.state.days,
                hours: str[0],
                minutes: str[1],
                tenantId: this.props.tenantId,
                surveyId: this.props.surveyId,
                state: true,
            };
            console.log(obj);
            this.setState({sending: true});

            /*await this.props.getUserSlack(this.context.accounts[0].username);

            let userId;

            for (let us of this.props.user) {
                if (us.teamId === this.props.selectedTeam) {
                    userId = us.id;
                }
            }*/


            let res = await axios.post("https://survey-schedule-new.azurewebsites.net/api/orchestrators/NewSchedule", obj);
            console.log(res);
            if (res.status === 202) {
                obj.id = res.data.id;
                /*obj.slackSurveyId = obj.surveyId;
                obj.slackUserId = userId;*/
                obj["userId"] = this.state.userId;

                await this.props.addScheduleSlack(obj);


                this.setState({message: "Successfully scheduled"});
                this.setState({success: true});
            }

// //--------//
//               obj.id="1234567891012";
//            await this.props.addScheduleSlack(obj);
//            this.setState({message: "Successfully scheduled"});
//             this.setState({ success: true });
//             //-------//


            this.setState({sending: false});
            this.props.getAllUserSurveys(this.context.accounts[0].localAccountId);
        }

    }

    handleDelete = async (scheduleId, reason) => {
        this.setState({sending: true});
        let res = await axios.post(`https://survey-schedule-new.azurewebsites.net/runtime/webhooks/durabletask/instances/${scheduleId}/terminate?reason=${reason}&taskHub=surveyschedulenew&connection=Storage&code=ITF661b72qJzHRV0oEtVK2P7WhuVEnKxXgDiF30alaxQAQZ2SdFVyA==`);
        console.log(res);
        if (res.status === 202) {
            console.log("Deleted an instance from azure");

            await this.props.deleteScheduleSlack(scheduleId);
            this.setState({
                sending: false,
                isOpen: true,
                message: "Successfully deleted",
                success: true});
            // this.props.setOpen(true);
        }


        if (reason !== "edits") {
            this.props.getAllUserSurveys(this.context.accounts[0].localAccountId);
        }


    }

    handleOpenMenu = (event) => {
        this.setState({openMenu: event.currentTarget})
    }

    handleCloseMenu = () => {
        this.setState({openMenu: null})
    }

    hasDays = () => {
        console.log(this.state.days);

        let has = false;
        this.state.days.map((day) => {
            if (day) {
                has = true;
            }
        });

        return has;
    }

    render() {

        return (
            <div className={this.props.children ? 'w-100' : ''}>
                <Tooltip title="Schedule">
                    {this.state.type === 'SCHEDULE' ?
                        this.props.children ?
                            <Button onClick={this.handleClickOpen}
                                    className={this.props.className}>{this.props.children}</Button>
                            :
                            <IconButton onClick={this.handleClickOpen}>
                                <Schedule/>
                            </IconButton>
                        :
                        this.props.children ?
                            <Button onClick={this.handleOpenMenu}
                                    className={this.props.className}>{this.props.children}</Button>
                            : <IconButton onClick={this.handleOpenMenu}><Schedule/></IconButton>
                    }
                </Tooltip>
                {this.state.type === 'EDIT' ?
                    <Menu anchorEl={this.state.openMenu} open={Boolean(this.state.openMenu)}
                          keepMounted
                          onClose={this.handleCloseMenu}
                          className="h-menu-share">
                        <MenuItem onClick={this.handleClickOpen}>
                            <Typography textAlign="center" className="text-black-50">
                                <History/> Edit
                            </Typography>
                        </MenuItem>
                        <MenuItem color="danger"
                                  onClick={() => this.handleDelete(this.props.scheduleId, "user canceled by clicking a button")}>
                            <Typography textAlign="center" className="text-danger">
                                <AutoDelete/> Delete
                            </Typography>
                        </MenuItem>
                    </Menu> : ''}
                <Dialog open={this.state.isOpen} onClose={this.handleClose}>
                    {this.state.success ?
                        <div className="successBlock">
                            <Typography>{this.state.message}</Typography>
                            <Button onClick={this.handleClose} color="primary">
                                Close
                            </Button>
                        </div> :
                        <div>
                            <DialogContent>
                                <p className="text-black-50">SELECT DAYS</p>

                                <FormGroup>
                                    {/*<FormGroup>
                                        <FormControlLabel control={<Switch checked={this.state.days[1]} name={'1'}
                                                                           onChange={this.handleDayChange}/>}
                                                          label="Monday"/>
                                        <FormControlLabel control={<Switch checked={this.state.days[2]} name={'2'}
                                                                           onChange={this.handleDayChange}/>}
                                                          label="Tuesday"/>
                                        <FormControlLabel control={<Switch checked={this.state.days[3]} name={'3'}
                                                                           onChange={this.handleDayChange}/>}
                                                          label="Wednesday"/>
                                        <FormControlLabel control={<Switch checked={this.state.days[4]} name={'4'}
                                                                           onChange={this.handleDayChange}/>}
                                                          label="Thursday"/>
                                        <FormControlLabel control={<Switch checked={this.state.days[5]} name={'5'}
                                                                           onChange={this.handleDayChange}/>}
                                                          label="Friday"/>
                                        <FormControlLabel control={<Switch checked={this.state.days[6]} name={'6'}
                                                                           onChange={this.handleDayChange}/>}
                                                          label="Saturday"/>
                                        <FormControlLabel control={<Switch checked={this.state.days[0]} name={'0'}
                                                                           onChange={this.handleDayChange}/>}
                                                          label="Sunday"/>
                                    </FormGroup>*/}

                                    <ToggleButtonGroup value={this.state.myDays}
                                                       onChange={this.handleDays}
                                                       color="primary"
                                                       className="justify-content-center"
                                                       aria-label="myDays">
                                        <ToggleButton value="1" aria-label="monday"
                                                      sx={{fontSize: {xs: '0.6rem', md: '1rem'}}}>
                                            Mon
                                        </ToggleButton>
                                        <ToggleButton value="2" aria-label="tuesday"
                                                      sx={{fontSize: {xs: '0.6rem', md: '1rem'}}}>
                                            Tue
                                        </ToggleButton>
                                        <ToggleButton value="3" aria-label="wednesday"
                                                      sx={{fontSize: {xs: '0.6rem', md: '1rem'}}}>
                                            Wed
                                        </ToggleButton>
                                        <ToggleButton value="4" aria-label="thursday"
                                                      sx={{fontSize: {xs: '0.6rem', md: '1rem'}}}>
                                            Thur
                                        </ToggleButton>
                                        <ToggleButton value="5" aria-label="friday"
                                                      sx={{fontSize: {xs: '0.6rem', md: '1rem'}}}>
                                            Fri
                                        </ToggleButton>
                                        <ToggleButton value="6" aria-label="saturday"
                                                      sx={{fontSize: {xs: '0.6rem', md: '1rem'}}}>
                                            Sat
                                        </ToggleButton>
                                        <ToggleButton value="0" aria-label="sunday"
                                                      sx={{fontSize: {xs: '0.6rem', md: '1rem'}}}>
                                            Sun
                                        </ToggleButton>
                                    </ToggleButtonGroup>

                                    {!this.state.hasDay && this.state.hasSubmit ?
                                        <div className="text-danger text-center fs-6">
                                            <small>You need to select at least one day</small>
                                        </div> : null}

                                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                                        <StaticTimePicker displayStaticWrapperAs="mobile"
                                                          value={this.state.dateTime}
                                                          onChange={this.handleTimeChange}
                                                          renderInput={(params) => <TextField {...params} />}/>
                                    </LocalizationProvider>
                                </FormGroup>

                            </DialogContent>

                            <DialogActions>
                                <div className="d-flex justify-content-center">
                                    <MutatingDots
                                        color={palette.primary}
                                        height="100"
                                        width="100"
                                        visible={this.state.sending}/>
                                </div>
                                <div className="text-end w-100">
                                    <Button onClick={this.handleClose} color="primary">
                                        Cancel
                                    </Button>

                                    <Button variant="contained" onClick={this.handleSubmit} color="primary">
                                        Ok
                                    </Button>
                                </div>

                            </DialogActions>
                        </div>
                    }

                </Dialog>
            </div>
        );
    }
}

// Map state to props;
const mapStateToProps = (state) => {
    return {
        user: state.users.user,
        // selectedTeam: state.users.selectedTeam
    };
};

// Map dispatch to props;
const mapDispatchToProps = (dispatch) => {
    return {

        addScheduleSlack: (obj) => dispatch(addScheduleThunk(obj)),
        deleteScheduleSlack: (id) => dispatch(deleteScheduleThunk(id)),
        getAllSurveysWithScheduleSlack: (teamId) => dispatch(getAllSurveysWithScheduleThunk(teamId)),
        getUserSlack: (email) => dispatch(getUserThunk(email)),
        getAllUserSurveys: (userId) => dispatch(getAllUserSurveysThunk(userId))
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(SlackDateTimePicker);