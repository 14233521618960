import React, {Component} from "react";
import { useMsal } from "@azure/msal-react";
import DropdownButton from "react-bootstrap/DropdownButton";
import Dropdown from "react-bootstrap/esm/Dropdown";
import Button from "react-bootstrap/Button";

import { MsalContext, UnauthenticatedTemplate, withMsal, AuthenticatedTemplate } from "@azure/msal-react";
/**
 * Renders a sign-out button
 */

class SignOutButton1 extends Component {


    static contextType = MsalContext;



    handleLogout = () => {
   
        this.context.instance.logoutRedirect({
            onRedirectNavigate: (url) => {
                
                // Return false if you would like to stop navigation after local logout
                return false;
            }
         
        });

        window.localStorage.clear();
        window.sessionStorage.clear();
        window.location.reload();
        

    }


    render (){
        return (
        // <DropdownButton variant="secondary" className="ml-auto" drop="left" title="Sign Out">
        //     <Dropdown.Item as="button" onClick={() => handleLogout("popup")}>Sign out using Popup</Dropdown.Item>
        //     <Dropdown.Item as="button" onClick={() => handleLogout("redirect")}>Sign out using Redirect</Dropdown.Item>
        // </DropdownButton>
        <Button onClick={() => this.handleLogout()}>Sign Out</Button>
    )
        }
}

export default SignOutButton1;
