import React, {Component, useState} from "react";
import {Chip, Stack, Typography} from "@mui/material";
import {EventRounded} from "@mui/icons-material";
import momentTz from "moment-timezone";
import moment from "moment";

class DisplaySchedule extends Component {

    // componentDidMount (){
    //     this.getDaysOfTheWeek(this.props.schedule-slack);
    // }

    state = {
        tz: momentTz.tz.guess()
    }
    getDaysOfTheWeek = (schedule) => {

        let days = {
            0: "Sun",
            1: "Mon",
            2: "Tue",
            3: "Wed",
            4: "Thur",
            5: "Fri",
            6: "Sat"
        }

        let daysString = [];

        for (let i = 0; i < schedule.days.length; i++) {

            console.log("loop");
            if (schedule.days[i] === true) {

                daysString.push(days[i]);
            }
        }

        return daysString;

    }

    constructTime = (hours, minutes) => {


        let prefix;
        if (hours >= 12) {
            prefix = "p.m"
        } else {
            prefix = "a.m"
        }
        let myHours = ((hours + 11) % 12 + 1);

        let myMinutes = minutes;
        if (minutes < 10) {

            myMinutes = "0" + myMinutes;

        }

        return myHours + ":" + myMinutes + " " + prefix;

    }

    newDate = () => {
        //current date Zone
        let d = moment();
        d.utc().hours(this.props.schedule.hours);
        d.utc().minutes(this.props.schedule.minutes);
        console.log('DATEEEEE', d.utc().format());
        d = new Date(d.utc().format());

        console.log('DATEEEEE NEWWW', d);
        return d;
    }


    render() {

        let days = this.getDaysOfTheWeek(this.props.schedule);
        let date = this.newDate();
        let time = this.constructTime(date.getHours(), date.getMinutes());

        return (
            <div>
                <div className="text-black-50 d-flex mb-2">
                    {'Scheduled: '}
                    <Stack direction="row" className="flex-wrap">
                        {days.map((day, index) => <Chip label={day} key={index} icon={<EventRounded/>} color="info" size="small" className="mb-1 ms-1"/>)}
                    </Stack>
                </div>
                <Typography className="text-info ms-2"><b>at {time} ({this.state.tz})</b></Typography>
            </div>
        )
    }
}

export default DisplaySchedule;