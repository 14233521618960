//THUNKS

import axios from "axios";
import {addUser, getUser} from "./users.actions";
import {addUserService, getUserService} from "../../services/user.service";

export const getUserThunk = (id) => {
    return async (dispatch) => {
        try {
            console.log("IDUSER", id);
            const data = await getUserService(id);
            console.log("datagetUser", data);
            if (!data.error) dispatch(getUser(data));
        } catch (error) {
            console.error(error);
        }
    };
};


export const addUserThunk = (obj) => {
    return async (dispatch) => {
        try {
            const { data } = await addUserService(obj);
            console.log("data", data);
            if (!data.error) dispatch(addUser(data));
        } catch (error) {
            console.error(error);
        }
    };
};
