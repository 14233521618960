import {
    Alert,
    Card,
    CardContent,
    CardHeader,
    CircularProgress,
    IconButton,
    Menu,
    MenuItem,
    Snackbar,
    Tooltip,
    Typography
} from "@mui/material";
import {MoreVert, PieChartOutline, Telegram, Visibility} from "@mui/icons-material";
import React, {useState} from "react";
import {format} from "date-fns";
import {Link} from "react-router-dom";
import './survey-card.css';
import SlackDateTimePicker from "../schedule-slack/SlackDateTimePicker";
import DisplaySchedule from "../schedule-slack/DisplaySchedule";
import {useTranslation} from "react-i18next";
import {useMsal} from "@azure/msal-react";

const createOptions = (name, routerLink, translation) => {
    return {
        name,
        routerLink,
        translation
    }
}

const SurveySlackCardItem = (props) => {
    const {t} = useTranslation();

    const {accounts} = useMsal();

    const options = [
        /*createOptions('Send a copy', ''),
        createOptions('Transfer', ''),
        createOptions('Make a copy', ''),
        createOptions('Create template', ''),
        createOptions('Clear response', ''),*/
        createOptions('Delete', '', t('surveys.delete')),
    ]

    const {item, sending, sendSurveyToTeam, deleteSurvey} = props;
    const [anchorEl, setAnchorEl] = useState(null);
    const [status, setStatus] = useState(item.active);
    const [open, setOpen] = useState(false);
    const [openShare, setOpenShare] = useState(null);

    const handleOpenMenu = (event) => {
        setAnchorEl(event.currentTarget);
    }

    const handleCloseMenu = (option = '') => {
        if (option === 'Delete') {
            if (window.confirm(`Are you sure to delete the Survey: ${item.title}?`)) {
                deleteSurvey(item.id);
            }
        }
        setAnchorEl(null);
    }

    const handleOpenShareMenu = (event) => {
        setOpenShare(event.currentTarget);
    }

    const handleCloseShareMenu = () => {
        setOpenShare(null);
    }

    const getActiveSchedule = (schedules) => {

        if (schedules.length > 0) {
            for (let schedule of schedules) {
                if (schedule.state === true) {
                    return schedule;
                }
            }
        }
        return false;
    }

    return (
        <div className="h-100">
            <Card className="h-100 position-relative">
                <CardHeader
                    action={
                        <div>
                            <IconButton onClick={handleOpenMenu}><MoreVert/></IconButton>
                            <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleCloseMenu}>
                                {options.map((option, index) =>
                                    <MenuItem key={index}
                                              onClick={() => handleCloseMenu(option.name)}>{option.translation}</MenuItem>)}
                            </Menu>
                        </div>
                    }
                    title={item.title}
                    subheader={item.description}/>
                <CardContent>
                    <Typography gutterBottom component="div" color="text.secondary">
                        {t('surveys.created')}: <b>{format(new Date(item.createdAt), 'MM/dd/yyyy')}</b>
                    </Typography>
                    <Typography gutterBottom component="div" color="text.secondary">
                        {t('surveys.modified')}: <b>{format(new Date(item.updatedAt), 'MM/dd/yyyy')}</b>
                    </Typography>
                    {/*<Typography gutterBottom variant="body2" color="text.info">
                        Questions: <b>{numberOfQuestions(item)}</b>
                    </Typography>*/}
                    <Typography gutterBottom variant="body2" color="text.info">
                        {t('surveys.responses')}: <b>{item.numberOfResponses || 0}</b>
                    </Typography>
                    {getActiveSchedule(item.schedules) ?
                        <DisplaySchedule schedule={getActiveSchedule(item.schedules)}/> : null}


                    <Typography gutterBottom variant="body2" color="text.info">
                        {sending[item.id] ?
                            <span className="d-flex align-items-center">
                                <CircularProgress/>
                                {t('sending')}:...
                            </span> : null}
                    </Typography>


                </CardContent>
            </Card>
            <div className="card-action-bottom border-top">
                <div className="d-flex justify-content-evenly animateHover">
                    <Tooltip title={t('survey.show')}>
                        <Link to={`/surveys-show/${item.id}`} params={{json: item.json}}>
                            <IconButton><Visibility/></IconButton>
                        </Link>
                    </Tooltip>
                    <Tooltip title={t('survey.sendTeam')}>
                        <IconButton disabled={sending[item.id]}
                                    onClick={() => sendSurveyToTeam(item.id)}><Telegram/></IconButton>
                    </Tooltip>

                    {
                        /*
                        <Link to={`/report/${item.id}`}>
                            <Tooltip title={t('item.results')}>
                                <IconButton><PieChartOutline/></IconButton>
                            </Tooltip>
                        </Link>
                        */
                    }

                    <Link to={`/charts/${item.id}`}>
                        <Tooltip title={t('item.results')}>
                            <IconButton><PieChartOutline/></IconButton>
                        </Tooltip>
                    </Link>

                    {getActiveSchedule(item.schedules) === false ?
                        <SlackDateTimePicker surveyId={item.id} tenantId={accounts[0].tenantId}/>
                        :<div>
                            <SlackDateTimePicker surveyId={item.id}
                                                 tenantId={accounts[0].tenantId}
                                                 setOpen={setOpen}
                                                 days={getActiveSchedule(item.schedules).days}
                                                 hours={getActiveSchedule(item.schedules).hours}
                                                 minutes={getActiveSchedule(item.schedules).minutes}
                                                 scheduleId={getActiveSchedule(item.schedules).id}/>
                        </div>
                    }
                </div>
            </div>
            <div>
                {/*<Snackbar open={open} autoHideDuration={3000} anchorOrigin={{vertical: 'top', horizontal: 'right'}}
                          onClose={() => setOpen(false)}>
                    {status ?
                        <Alert severity="success" sx={{width: '100%'}} onClose={() => setOpen(false)}>
                            {t('item.play')}
                        </Alert> :
                        <Alert severity="warning" sx={{width: '100%'}} onClose={() => setOpen(false)}>
                            {t('item.pause')}
                        </Alert>
                    }

                </Snackbar>*/}


                <Snackbar open={open} autoHideDuration={3000} anchorOrigin={{vertical: 'top', horizontal: 'right'}}
                          onClose={() => setOpen(false)}>
                        <Alert severity="success" sx={{width: '100%'}} onClose={() => setOpen(false)}>
                            {'Schedule removed successfully'}
                        </Alert>
                    }
                </Snackbar>
            </div>
        </div>
    )
}

export default SurveySlackCardItem;