import { Chart } from "chart.js";

export const floatingLabels = {
  id: "floatingLabels",
  afterDatasetsDraw: (chart: Chart, args: any, options: any) => {
    const {
      ctx,
      // chartArea: { top },
      chartArea: { right },
      scales: { x, y },
    } = chart;

    /*
    for (let i = 0; i < chart.config.data.datasets[1].data.length; i++) {
      const xPosition = chart.getDatasetMeta(1).data[i].x;
      const yPosition = top + 6;

      ctx.save();

      console.log(chart.isDatasetVisible(1));
      if (chart.isDatasetVisible(1) === true) {
        ctx.fillStyle = "rgba(255, 26 , 104, 1)";
      } else {
        ctx.fillStyle = "transparent";
      }

      ctx.textAlign = "center";
      ctx.font = "bolder 12px Arial";
      ctx.fillText(chart.config.data.datasets[1].data[i], xPosition, yPosition);
    }
    */

    for (let i = 0; i < chart.config.data.datasets[0].data.length; i++) {
      const xPosition = right + 6;
      const yPosition = chart.getDatasetMeta(0).data[i].y;

      ctx.save();

      console.log(chart.isDatasetVisible(0));
      if (chart.isDatasetVisible(0) === true) {
        // ctx.fillStyle = "rgba(255, 26, 104, 1)";
        ctx.fillStyle = "rgba(0, 0, 0, 1)";
      } else {
        ctx.fillStyle = "transparent";
      }

      ctx.textAlign = "center";
      ctx.font = "bolder 12px Arial";

      ctx.fillText(
        chart.config.data.datasets[0].data[i]?.toString() ?? "",
        xPosition,
        yPosition
      );
    }
  },
};
