import { useNavigate } from "react-router-dom";
import { ArrowBack as ArrowBackIcon } from "@mui/icons-material";
import { Item } from "./Item";
import { Typography,Box } from "@mui/material";

interface Props {
  title: string;
}

export function ReturnBar({ title }: Props): JSX.Element {
  const navigate = useNavigate();
  const today = new Date();
  return (
    <>
      <Box height={'80px'} alignItems={'center'} boxShadow={'3'} borderRadius={'10px'} padding={'10px'}>
        <Typography fontSize={'22px'} fontFamily={'IBM Plex Sans'} fontWeight={'SemiBold'} color={'#1A237E'} onClick={() => navigate(-2)}>
          <ArrowBackIcon style={{cursor: "pointer"}}/> {title}
        </Typography>
        <Typography fontSize={'16px'} fontFamily={'IBM Plex Sans'} fontWeight={'Regular'} color={'#1A237E'} >{today.toDateString()}</Typography>
      </Box>
    </>
  );
}
