import SurveyActionTypes from "./survey.types";

const INITIAL_STATE = {
  allSurveys: [],
  singleSurvey: null,
  singleQuestion: null,
  questions: [],
  templates: [],
  singleTemplate: null,
};


const surveysReducer = (state = INITIAL_STATE, action) => {
  console.log("PL", action);
  switch (action.type) {
    case SurveyActionTypes.GET_ALL_SURVEYS:
      return { ...state, allSurveys: action.payload };
    case SurveyActionTypes.GET_ALL_SURVEYS_WITH_SCHEDULE:
      return { ...state, allSurveys: action.payload };
    case SurveyActionTypes.GET_SINGLE_SURVEY:
      return { ...state, singleSurvey: action.payload };
    case SurveyActionTypes.ADD_NEW_SURVEY:
      return { ...state, singleSurvey: action.payload };
    case SurveyActionTypes.ADD_NEW_QUESTION:
      return { ...state, singleQuestion: action.payload };
    case SurveyActionTypes.ADD_NEW_ANSWER:
      return { ...state };
    case SurveyActionTypes.ADD_RESPONSE:
      return { ...state };
    case SurveyActionTypes.GET_ALL_QUESTIONS:
      return { ...state, questions: action.payload };
    case SurveyActionTypes.GET_SINGLE_SURVEY_WITH_QUESTIONS_AND_ANSWERS:
      return { ...state, singleSurvey: action.payload };
    case SurveyActionTypes.DELETE_SURVEY:
      return { ...state };
      case SurveyActionTypes.DELETE_SCHEDULE:
        return { ...state };
    case SurveyActionTypes.ADD_JSON_CARD_TO_SURVEY:
      return { ...state };
    case SurveyActionTypes.ADD_SCHEDULE:
      return {...state};
    case SurveyActionTypes.GET_ALL_TEMPLATES:
      return {...state, templates: action.payload}
    case SurveyActionTypes.GET_SINGLE_TEMPLATE:
      return {...state, singleTemplate: action.payload}

    default:
      return state;
  }
};

export default surveysReducer;
