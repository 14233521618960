import axios from "axios";
import { Survey } from "../models/Survey";
import { SurveyConverter } from "../utils/data-conversion/surveyConverter";
// import { SurveyFaker } from "../utils/surveyFaker/surveyFaker";

export class SurveyService {
  private static allSurveysURL: string =
    "https://survey-app-backend.azurewebsites.net/api/surveys/allSurveys";
  private static singleSurveyFullURL: string =
    "https://survey-app-backend.azurewebsites.net/api/surveys/singleSurveyFull";
  private static singleSurveyWithRURL: string =
    "https://survey-app-backend.azurewebsites.net/api/surveys/singleSurveyWithR";

  public static async getSurvey(id: string): Promise<Survey> {
    let singleSurvey = {} as Survey;

    let singleSurveyFull: any = (
      await axios.get(this.singleSurveyFullURL + "/" + id)
    ).data;
    let singleSurveyWithR: any = (
      await axios.get(this.singleSurveyWithRURL + "/" + id)
    ).data;

    const surveyConverter = new SurveyConverter(
      singleSurveyFull,
      singleSurveyWithR,
      "teams"
    );
    surveyConverter.generateSurvey();
    singleSurvey = surveyConverter.getSurvey();

    // singleSurvey = SurveyFaker.generateSurvey("generic");

    console.log("survey:", singleSurvey);

    return singleSurvey;
  }

  public static async getAllSurveys(
    id = "d09675f1-5567-4d98-9e73-44e1ecd3c3bb"
  ): Promise<Survey[]> {
    let allSurveys = [] as Survey[];
    allSurveys = (await axios.get(this.allSurveysURL + "/" + id)).data;
    //allSurveys = SurveyFaker.generateSurveys(10);
    console.log("all surveys:", allSurveys);
    return allSurveys;
  }

  public static async getAllSurveysWithResponse(
    id = "d09675f1-5567-4d98-9e73-44e1ecd3c3bb"
  ): Promise<any[]> {
    /*
    let allSurveys = [] as Survey[];
    allSurveys = (await axios.get(this.allSurveysURL + id)).data;
    const ans = [];
    for (const current of allSurveys) {
      ans.push(await axios.get(this.singleSurveyWithRURL + current.id));
    }
    return ans;
    */

    throw new Error("Function not implemented.");
  }
}
