import MenuItem from "@mui/material/MenuItem";
import Typography from "@mui/material/Typography";
import Menu from "@mui/material/Menu";
import * as React from "react";
// import CustomerPortal from "../OldComponents/HomePage/CustomerPortal";
import {Divider} from "@mui/material";
import {useTranslation} from "react-i18next";

const MenuUser = ({...props}) => {
    const {t} = useTranslation();
    const {anchorElUser, handleCloseUserMenu, settings} = props;
    return (
        <Menu
            sx={{mt: '45px'}}
            id="menu-appbar"
            anchorEl={anchorElUser}
            anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
            }}
            keepMounted
            transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
            }}
            open={Boolean(anchorElUser)}
            onClose={() => handleCloseUserMenu()}>
            {/*<CustomerPortal email={email} handleCloseUserMenu={handleCloseUserMenu} handleTeamChange={handleTeamChange} team={team} t={t}/>*/}
            {settings.map((setting, index) => (
                <span key={index}>
                    {index === settings.length - 1 ? <Divider variant="middle"/> : null}
                    <MenuItem onClick={() => handleCloseUserMenu(setting)} key={index}>
                        <Typography textAlign="center">{setting.translation}</Typography>
                    </MenuItem>
                </span>
            ))}
        </Menu>
    )
}
export default MenuUser;