import React from "react";
import { useMsal } from "@azure/msal-react";
import { loginRequest } from "../authConfig";
import {Button} from "@mui/material";

import { useIsAuthenticated } from "@azure/msal-react";
/**
 * Renders a drop down button with child buttons for logging in with a popup or redirect
 */
export const SignInButton = (props) => {
  const { instance } = useMsal();

  const handleLogin = (loginType) => {
    if (loginType === "popup") {
      instance.loginPopup(loginRequest).catch((e) => {
        console.log(e);
      });
    } else if (loginType === "redirect") {
      instance.loginRedirect(loginRequest).catch((e) => {
        console.log(e);
      });
    }
  };
  return (
    // <DropdownButton
    //   variant="secondary"
    //   className="ml-auto"
    //   drop="left"
    //   title="Sign In"
    // >
    //   <Dropdown.Item as="button" onClick={() => handleLogin("popup")}>
    //     Sign in using Popup
    //   </Dropdown.Item>
    //   {/* <Dropdown.Item as="button" onClick={() => handleLogin("redirect")}>
    //     Sign in using Redirect
    //   </Dropdown.Item> */}
    // </DropdownButton>
  
    //<Button onClick={() => handleLogin("popup")}>Sign In</Button>
    <Button {...props} onClick={() => handleLogin("redirect")}>Sign In</Button>
  )
};
