import UserActionTypes from "./users.types";

//ACTION CREATORS

export const getUser = (payload) => ({
  type: UserActionTypes.GET_USER,
  payload,
});

export const addUser = (payload) => ({
  type: UserActionTypes.ADD_USER,
  payload,
});