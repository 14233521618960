//function that converts survey obj from the db to a json for microsoft card

let convertToJsonCard = (survey) => {
    let card = {
        $schema: "http://adaptivecards.io/schemas/adaptive-card.json",
        version: "1.3",
        type: "AdaptiveCard",
        body: [

            //survey title
            {
                type: "TextBlock",
                size: "Medium",
                weight: "Bolder",
                text: survey.title

            },

            //survey description
            {
                type: "TextBlock",
                text: survey.description,
                wrap: true
            },

            //questions will go here
        ],
        actions: [
            {
                type: "Action.Submit",
                title: "Submit",
                associatedInputs: "auto",
                data: {
                    ID: survey.id
                }
            }
        ],

    }

    let questions = survey.questions;
    let answers = survey.answers;

    for (let ques of questions) {

        card.body.push({
            type: "TextBlock",
            text: ques.content,
            wrap: true
        })

        if (ques.type === "Text" || ques.type === "text") {

            card.body.push({
                type: "Input.Text",
                placeholder: "Your response",
                id: ques.id
            })

        } else {


            let obj = {
                type: "Input.ChoiceSet",
                placeholder: "Placeholder text",
                choices: [],
                wrap: true,
                // "isMultiSelect": true,
                id: ques.id
            }

            if (ques.type === "Checkbox" || ques.type === "checkbox") {
                obj.isMultiSelect = true;
            }

            if (ques.type === "Radio" || ques.type === "radio" || ques.type === "radiogroup") {
                obj.style = "expanded"
            }

            for (let ans of answers) {
                if (ques.id === ans.questionId) {


                    obj.choices.push({
                        title: ans.content,
                        value: ans.id,
                        wrap: true

                    })


                }
            }

            card.body.push(obj);
        }
    }
    console.log(card);
    return card;

}

export default convertToJsonCard;

