import UserActionTypes from "./users.types";

const INITIAL_STATE = {
  user: null,
};

const usersReducer = (state = INITIAL_STATE, action) => {
  console.log("PL", action);
  switch (action.type) {
    case UserActionTypes.GET_USER:
      return { ...state, user: action.payload };
    case UserActionTypes.ADD_USER:
      return { ...state, user: action.payload };

    default:
      return state;
  }
};

export default usersReducer;
